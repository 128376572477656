<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`${modalId}`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="`${modalId}_Label`">
            <i class="bi bi-trash"></i>
            {{
              $t(
                "Components.AreYouSureModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            v-if="!this.buttonDisabled"
            @click="clear"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <span class="text-danger" v-if="errors.length == 0"
            ><i class="bi bi-exclamation-circle"></i>
            {{ warningMessage }}
          </span>
          <div v-if="errors.length > 0" class="alert alert-warning">
            <ul class="mb-0">
              <li v-for="error in errors" v-bind:key="error">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
        <div
          class="modal-footer"
          :class="
            errors.length == 0
              ? 'justify-content-between'
              : 'justify-content-right'
          "
        >
          <label
            :for="`deleteDataCheck`"
            v-if="errors.length == 0"
            :class="{ shake: shakeAnimate }"
          >
            <input
              type="checkbox"
              :id="`deleteDataCheck`"
              class="me-1"
              :checked="confirm"
              v-model="confirm"
              :disabled="this.buttonDisabled"
            />
            <text>{{
              $t(
                "Components.AreYouSureModal.IConfirm",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</text>
          </label>
          <div>
            <button
              type="button"
              class="btn btn-danger me-2 delete-record"
              v-if="errors.length == 0"
              :disabled="this.buttonDisabled"
              @click="onButtonClick"
            >
              <span v-if="modalType == CompanyResetCache">
                <i class="bi bi-recycle"></i>
                {{
                  $t(
                    "Components.AreYouSureModal.ResetButton",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span
              >
              <span v-if="modalType == CompanyClearCache">
                <i class="bi bi-x-circle-fill"></i>
                {{
                  $t(
                    "Components.AreYouSureModal.ClearButton",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span
              >
              <span v-if="modalType == 'UndoDeleteUser'">
                <i class="bi bi-x-circle-fill"></i>
                {{
                  $t(
                    "Components.AreYouSureModal.UndoDeleteButton",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span
              >
              <span v-if="modalType == 'MonitoringCancelJob'">
                <i class="bi bi-x-circle-fill"></i>
                {{
                  $t(
                    "Components.AreYouSureModal.CancelButton",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span
              >
            </button>
            <button
              type="button"
              class="btn btn-success btn-close-modal"
              @click="clear"
              v-if="!this.buttonDisabled"
              data-bs-dismiss="modal"
            >
              <i class="bi bi-x"></i>
              {{
                $t(
                  "Components.AreYouSureModal.Close",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
export default {
  name: "AreYouSureModal",
  data() {
    return {
      errors: [],
      shakeAnimate: false,
      confirm: false,
      buttonDisabled: false,
      submitButtonHtml: String.format(
        '<span class="loading-block d-flex justify-content-center align-items-center"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="ms-2">{0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      CompanyResetCache: "ResetCompanyCache",
      CompanyClearCache: "ClearCompanyCache",
      isGatewayRequest: true,
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    modalId: {
      type: String,
      default: "modalAreYouSure",
    },
    modalType: {
      type: String,
      default: "ResetCompanyCache",
    },
    requestParameter: {
      type: String,
      default: "",
    },
    warningMessage: {
      type: String,
      default: "",
    },
    isBrsAxios: {
      type: Boolean,
      default: false,
    },
    isGatewayAxios: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    shake() {
      this.shakeAnimate = true;
      setTimeout(() => {
        this.shakeAnimate = false;
      }, 3000);
    },
    clear: function () {
      this.confirm = false;
      this.buttonDisabled = false;
      this.errors = [];
    },
    success: function ($firstSpan, $loadingBlock) {
      $firstSpan.show();
      $loadingBlock.remove();
      this.buttonDisabled = false;
      this.confirm = false;
      setTimeout(() => {
        $(".btn-close-modal").trigger("click");
      }, 300);
    },
    error: function ($firstSpan, $loadingBlock) {
      $firstSpan.show();
      $loadingBlock.remove();
      this.buttonDisabled = false;
      this.confirm = false;
    },
    postHttpRequest(instance, firstSpan, loadingBlock) {
      var self = this;
      if (this.isGatewayAxios) {
        instance
          .post(
            String.format(
              "Lcdp-{0}?id={1}",
              this.modalType,
              this.requestParameter
            )
          )
          .then((response) => {
            const result = response.data;
            if (result.isOk) {
              this.success(firstSpan, loadingBlock);
              createToast(
                self.$t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: self.$store.state.activeLang }
                ),
                {
                  showIcon: "true",
                  position: "top-right",
                  type: "success",
                  transition: "zoom",
                }
              );
              this.$refs.deleteUsersGrid.grid.refresh();
            } else {
              this.error(firstSpan, loadingBlock);
              if (!String.isNullOrWhiteSpace(result.msg))
                self.errors.push(result.msg);
              if (!String.isNullOrWhiteSpace(result.message))
                self.errors.push(result.message);
            }
          })
          .catch(function (error) {
            self.errors.push(error);
          });
      } else if (this.isBrsAxios) {
        if (!String.isNullOrWhiteSpace(this.data)) {
          instance
            .post(String.format("Brs-{0}", this.modalType), this.data)
            .then((response) => {
              const result = response.data;
              if (result.isOk) {
                this.success(firstSpan, loadingBlock);
                createToast(
                  self.$t(
                    "Messages.SuccessMessage",
                    {},
                    { locale: self.$store.state.activeLang }
                  ),
                  {
                    showIcon: "true",
                    position: "top-right",
                    type: "success",
                    transition: "zoom",
                  }
                );
              } else {
                this.error(firstSpan, loadingBlock);
                if (!String.isNullOrWhiteSpace(result.msg))
                  self.errors.push(result.msg);
                if (!String.isNullOrWhiteSpace(result.message))
                  self.errors.push(result.message);
              }
            })
            .catch(function (error) {
              self.errors.push(error);
            });
        } else {
          instance
            .post(
              String.format(
                "Brs-{0}?publicId={1}",
                this.modalType,
                this.requestParameter
              )
            )
            .then((response) => {
              const result = response.data;
              if (result.isOk) {
                this.success(firstSpan, loadingBlock);
                createToast(
                  self.$t(
                    "Messages.SuccessMessage",
                    {},
                    { locale: self.$store.state.activeLang }
                  ),
                  {
                    showIcon: "true",
                    position: "top-right",
                    type: "success",
                    transition: "zoom",
                  }
                );
              } else {
                this.error(firstSpan, loadingBlock);
                if (!String.isNullOrWhiteSpace(result.msg))
                  self.errors.push(result.msg);
                if (!String.isNullOrWhiteSpace(result.message))
                  self.errors.push(result.message);
              }
            })
            .catch(function (error) {
              self.errors.push(error);
            });
        }
      }
    },
    onButtonClick: function () {
      if (!this.confirm || String.isNullOrWhiteSpace(this.requestParameter)) {
        this.shake();
        return;
      }

      this.buttonDisabled = true;

      var button = $(String.format(".delete-record")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block");

      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      var instance = this.$appAxios;
      if (this.isBrsRequest) {
        instance = this.$brsAxios;
      } else if (this.isGatewayRequest) {
        instance = this.$prodGatewayAxios;
      }

      let self = this;
      self.postHttpRequest(instance, firstSpan, loadingBlock);
    },
  },
  mounted() {},
};
</script>
